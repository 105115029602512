<template>
  <v-dialog
    v-model="internalShowing"
    max-width="800px"
  >
    <v-card>
      <v-card-title>Añadir vigilante al cuadrante</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <vigilante-form
            :iddelegacion="iddelegacion"
            @change-values="changeFormData"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="internalShowing = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click.stop="clickSubmit($refs.form)"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { formPageMixin } from '@/mixins/formPageMixin'
import VigilanteForm from './VigilanteForm'

export default {
  components: {
    VigilanteForm
  },
  mixins: [internalShowingMixin, formPageMixin],
  props: {
    iddelegacion: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      this.$emit('submit', this.formData)
      this.internalShowing = false
    },
  },
}
</script>
