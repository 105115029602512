<template>
  <b10-base>
    <b10-autocomplete
      v-model="form.idvigilante"
      :rules="formRules.idvigilante"
      :items="vigilantes"
      item-value="idvigilante"
      item-text="nombre_vigilante"
      label="Vigilante"
    />
    <v-checkbox
      v-model="form.esHabitual"
      label="Añadir como vigilante habitual del servicio"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './VigilanteFormData'

export default {
  mixins: [formMixin],
  props: {
    iddelegacion: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      vigilantes: [],
      form: {
        idvigilante: null,
        esHabitual: false
      },
      formRules: {
        idvigilante: [
          v => !!v || 'Campo requerido'
        ],
      },
    }
  },
  async created () {
    const [dataset] = await Data.selectVigilantes (this, this.iddelegacion)
    this.vigilantes = dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
  },
}
</script>
