import { APIFilter } from '@/utils/api'

export default {
  async selectVigilantes (Vue, iddelegacion) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('iddelegacion', iddelegacion)
      .addGT('estado', 0)
    const resp = await Vue.$api.call(
      'vigilante.select',
      {
        filter: apiFilter,
        sorter: [
          { 'field': 'nombre_vigilante', 'asc': true }
        ]
      }
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
